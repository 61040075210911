import * as React from "react"
import { graphql, PageProps  } from "gatsby";

import Layout from "../components/layout"
import Seo from "../components/seo"
import Main, {MainProps} from '../components/main';

import "../styles/style.css"

import "../styles/namari-color.css"

import "../styles/font-awesome.css"
import "../styles/accordion.css"

import "../styles/animate.css"
import "../styles/jquery-modal.min.css"
import "../styles/modal.css"

import "../styles/main.css"

interface strapiDatenschutzProps extends MainProps {
  text: string;
}

type IndexPageProps = PageProps<{
  strapiDatenschutz: strapiDatenschutzProps
}>

const IndexPage = ({ data }: IndexPageProps) => {
  const { strapiDatenschutz } = data;

  return (
    <Layout>
      <Seo title="Home" />
      <Main
        headline={strapiDatenschutz.headline}
      />
      <div className='row'>
        <div className='col-1' dangerouslySetInnerHTML={{__html: strapiDatenschutz.text}} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiDatenschutz {
      headline
      text
    }
  }
`;

export default IndexPage
